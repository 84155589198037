
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontacto } from '../../api/contacto.api'

import './sobremi.scss';






const Psobremi = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);


  
  
  
  

  
  return (
    <div id="id65e1dee2b0184704f9093bea" >

      <div id="ixru" className="">
      
      <div id="ij34" className="">
      
      <div id="i7mce" className="">
      
      <img
        className=""
        id="ikq21p"
        src="https://assetsprojects.s3.amazonaws.com/406raciltpy3ned.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="icviq" className="">
      
        <a
          target=""
          className=""
          id="ie1gg"
          href={`/`}
        >
          
    <div id="it4d8" className="">
    <span>Inicio</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
        <a
          target=""
          className="activo-pagina "
          id="iotjc"
          href="#"
        >
          
    <div id="infbh" className="">
    <span>Mi Historia</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="iierj"
          href={`/contacto`}
        >
          
    <div id="incnh" className="">
    <span>Contacto</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
      </div>
      
      <div id="is5eph" className="">
      
        <a
          target=""
          className=""
          id="imj1uz"
          href={`/mihistoria`}
        >
          
      <img
        className=""
        id="id3wfp"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am4aa.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iak5x5"
          href={`/aboutme`}
        >
          
      <img
        className=""
        id="i3qsiz"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am355.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="ihonp" className="">
      
      <div id="i4fgxk" className="">
      
      </div>
      
      <div id="iuapqr" className="">
      
      <div
        id="ig5e5f"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <p className="" id="i6qam9">
        <span>SOBRE MI</span>
      </p>
      
      <div id="" className="swiper mySwiper ">
      
      <div id="" className="swiper-wrapper ">
      
      <div id="iriexs" className="swiper-slide ">
      
      <img
        className=""
        id="ihe61c"
        src="https://assetsprojects.s3.amazonaws.com/406raciltj2jpcx.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="i5fibr" className="swiper-slide ">
      
      <img
        className=""
        id="ixchdl"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtibr8y.jpeg"
        alt="undefined"
      />
      
      </div>
      
      <div id="iiqnnj" className="swiper-slide ">
      
      <img
        className=""
        id="i60r7q"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxticls1.jpeg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="" className="swiper-button-next ">
      
      </div>
      
      <div id="" className="swiper-button-prev ">
      
      </div>
      
      <div id="" className="swiper-pagination ">
      
      </div>
      
      </div>
      
      <img
        className="img-sobremi "
        id="im9g2x"
        src="https://assetsprojects.s3.amazonaws.com/406raciltj2jpcx.jpg"
        alt="undefined"
      />
      
      <img
        className="img-sobremi "
        id="ifdclg"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtibr8y.jpeg"
        alt="undefined"
      />
      
      <img
        className="img-sobremi "
        id="isk6e7"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtifwi4.jpeg"
        alt="undefined"
      />
      
      </div>
      
      <div
        id="ilfx8a"
        className="backgroundprincipal "
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <div id="izmiak" className="">
      
      <p className="" id="inozh">
        <span>Madre de dos hijos, enamorada de la vida y lo extraordinario, me apasiona el bienestar mental para lograr un crecimiento personal y profesional continuo.                                                                                                                                                                                                  Como Directora y Gerente General de IMACEM Consultores S.A. te asesoro en materia de comunicación e imagen y brindo diversas capacitaciones y servicios de moderación y embajadora de marca. Como apasionada de la vida te comparto experiencias y aprendizajes desde el corazón que espero te sirvan en tu crecimiento integral hacia tu mejor versión.                                                                                            Les comparto un poco de lo que he aprendido de la vida hasta ahora:</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí que la felicidad es vivir a plenitud los instantes… a los sueños no se renuncia y que nunca es tarde para comenzar, que las locuras son la sal de la vida. Que la empatía es vital y nos acerca a los demás, que no debes juzgar a nadie; no sabes las luchas y cruces de cada quien.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí que aceptar la voluntad de Dios da paz pero que debes hacer que las cosas pasen, lo que vale la pena no es sencillo. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí a tolerar, que las creencias rígidas hacen más daño que bien, pero los principios y valores son innegociables. A ser resiliente y seguir adelante con fortaleza y amor.  </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí a mostrarme a mis hijos tan humana e imperfecta como soy, a sentir cada emoción de principio a fin. Que la estructura familiar a veces cambia, y no es el fin, es parte de la vida. Que los finales permiten el inicio de etapas maravillosas. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí que sin importar la prueba, Dios está ahí. Que el sufrimiento es inevitable y cuando viene, en la presentación que sea, debemos aceptarlo y enfrentarlo con brazos abiertos, no por conformismo, pero por realismo. Que de cada sufrimiento debe haber un aprendizaje, porque si no, habrá sido en vano. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí a tragar amargo y a sonreir con dulzura. Que es tan importante reír como llorar.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí a perdonar y a que me perdonaran, a olvidar y a priorizar. Que la vida privada se debe mantener así, no por apariencia, si no por respeto.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí a agradecer más y con más fuerza. A disfrutar el instante, a valorar el momento y a abrazar los recuerdos… a dar más de mi tiempo y conocimiento a los demás.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí que debemos mostrarnos siempre reales y que los sacrificios engrandecen nuestro corazón. A vivir más intensamente cada experiencia, a disfrutar y a amar mejor y más profundamente a mis hijos, familia y amistades. Que para bailar y cantar solo necesito estar yo. Que hay oportunidades únicas que no debemos dejar ir. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí a estar más disponible para escuchar y ayudar, a vivir estando presente en cada segundo. A expresar con pasión lo que siento y a callar para no lastimar, a ser más humana y reconocer mis virtudes y defectos. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí a recordar que la magia entre personas y situaciones existe. A que nunca dejaré de aprender, luchar, ganar, perder, equivocarme y acertar y que en este camino de la vida, debemos ser luz…que todos sentimos, sufrimos, celebramos y reaccionamos distinto y eso, se respeta. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí que la soledad es un regalo si llegas a entenderla y que debes ser feliz primero para poder ser feliz junto a los demás.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Aprendí a ser mi mejor versión y entendí que el día que haya cumplido con mi misión ese será mi último día, ni antes ni después.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Así que acá estoy, con brazos abiertos recibiendo todo lo que la vida manda y no puedo controlar; y haciendo obras de arte con lo que sí puedo. </span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Feliz, plena, apasionada, realizada,completa y orgullosa de las cicatrices que la vida me deja porque me recuerdan lo bello que he vivido.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i5iwlc" className="">
      
      <div id="i1a2e2" className="">
      
      <p className="" id="ih4brn">
        <span>Educación</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i2alg" className="">
      
      <div
        id="i62gd"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <div
        id="i61c44"
        className=""
        data-aos="fade-down"
        data-aos-duration=""
      >
      
      <div id="igzrx" className="item-info ">
      
      <div id="inn52i" className="">
      
      <div id="ipjf5e" className="">
      
      <div id="idqgxz" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="itraap" className="">
      
      <p className="" id="iijj63">
        <span>2022 - 2024</span>
      </p>
      
      <p className="" id="inl3fr">
        <span>Máster en Comunicación e Identidad Corporativa</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i4dziv" className="">
      
      <div id="i1rzgy" className="">
      
      <div id="i3ix44" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="i3nkjw" className="">
      
      <p className="" id="i9u5nf">
        <span>2006 - 2007</span>
      </p>
      
      <p className="" id="ivbjb3">
        <span>Máster en Dirección y Administración de Empresas, MBA</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i3ccyu" className="">
      
      <div id="iqfi7v" className="">
      
      <div id="i32chu" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="itb8q2" className="">
      
      <p className="" id="ix5eog">
        <span>1998 - 2003</span>
      </p>
      
      <p className="" id="izrqeh">
        <span>Licenciatura en Comunicación y Publicidad, Estudios sobre comunicación y medios</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i8w4dl" className="">
      
      <div id="ius19c" className="">
      
      <p className="" id="iljrou">
        <span>Experiencia</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i9k90x" className="">
      
      <div
        id="irbt2i"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <div
        id="it70au"
        className=""
        data-aos="fade-down"
        data-aos-duration=""
      >
      
      <div id="in2zjr" className="item-info ">
      
      <div id="i2vjm8" className="">
      
      <div id="ilvv9p" className="">
      
      <div id="igesk2" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="iw1aq6" className="">
      
      <p className="" id="iobyrj">
        <span>2024 - Presente</span>
      </p>
      
      <p className="" id="i7dv09">
        <span>Directora y Gerente General de IMACEM Consultores S.A.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i8hzwa" className="">
      
      <div id="ivw2ch" className="">
      
      <div id="iz782j" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="ilprw6" className="">
      
      <p className="" id="i46gwu">
        <span>2019 - 2020</span>
      </p>
      
      <p className="" id="i6t2gv">
        <span>Radio Talk Show Host de Central Hondureña de Radios - Suprema FM</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i5cjqj" className="">
      
      <div id="ii33y5" className="">
      
      <div id="i4g3yn" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="i2rnww" className="">
      
      <p className="" id="il8k1j">
        <span>2001 - Presente</span>
      </p>
      
      <p className="" id="idoj0p">
        <span>Maestra de Ceremonias - Moderadora, Freelance</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="il84dp" className="">
      
      <div id="i4g3vk" className="">
      
      <div id="iafxd5" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="ix142j" className="">
      
      <p className="" id="i43pv2">
        <span>2009 - 2019</span>
      </p>
      
      <p className="" id="ijqop8">
        <span>Presentadora / Coordinadora Noticiero de Televisión de Televicentro y Emisoras Unidas</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i0y9sl" className="">
      
      <div id="id6cbq" className="">
      
      <div id="i2octj" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="ivja9p" className="">
      
      <p className="" id="igrplm">
        <span>2004 - 2008</span>
      </p>
      
      <p className="" id="i6ci6f">
        <span>Jefe de Protocolo de Banco Centroamericano de Integración Económica</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ihfuyv" className="">
      
      <div id="i7u9gn" className="">
      
      <div id="iaissi" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="im454f" className="">
      
      <p className="" id="ic332g">
        <span>2001 - 2004</span>
      </p>
      
      <p className="" id="iwwuze">
        <span>Productora y News Anchor de Televicentro y Emisoras Unidas</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i0kwi" className="">
      
      <div
        id="i0uyoc"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="ikxkxh" className="">
      
      <p className="" id="ispf5r">
        
      <b className="" id="iyuk7m">
        <span>Contáctame</span>
      </b>
      
      </p>
      
      </div>
      
        <div className="" id="i367ii">
          
      <form
        id="illm7i"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontacto({
						nombrecompleto: e.target.nombrecompleto.value,
						correo: e.target.correo.value,
						mensaje: e.target.mensaje.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ihr8s4" className="">
      
      <br className="" id="" />
      
      <input
        name="nombrecompleto"
        type="text"
        placeholder="Nombre Completo"
        className="campos-contacto "
        id="ifrzbt"
      />
      
      </div>
      
      <div id="i65x8n" className="">
      
      <br className="" id="" />
      
      <input
        name="correo"
        type="text"
        placeholder="Correo"
        className="campos-contacto "
        id="iygjnf"
      />
      
      </div>
      
      <div id="i269cz" className="">
      
      <br className="" id="" />
      
      <input
        name="mensaje"
        type="text"
        placeholder="Mensaje"
        className="campos-contacto "
        id="iyid9o"
      />
      
      </div>
      
      <div id="ivb8of" className="">
      
      <button
        type="submit"
        name=""
        id="im973i"
        className=""
        
      >
      
      <div id="inu4vk" className="">
      <span>Enviar</span>
      <span className="" id="iksjh6">
        
      </span>
      
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      <div id="imtnsk" className="backgroundprincipal ">
      
      <div id="i5ajok" className="">
      
      <img
        className=""
        id="i1fr26"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvvmind7.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="igc5kf" className="">
      
      <div id="iu4568" className="">
      
        <a
          target=""
          className=""
          id="iep8l"
          href="mailto:info@evangelinabarquero.com"
        >
          
      <img
        className="icono-social "
        id="i7z62"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8q7t76.png"
        alt="undefined"
      />
      
      <p className="" id="izcqh">
        
      <a
        target=""
        className=""
        id="iay8fh"
        href="mailto:contact@imacempro.com"
        type=""
      >
        <span>contact@imacempro.com</span>
      </a>
      
      </p>
      
        </a>
        
      </div>
      
      <div id="i13qdh" className="">
      
      <div id="ipxsda" className="">
      
        <a
          target=""
          className="item-social "
          id="ifnwb1"
          href="https://twitter.com/EvanBarqueroT"
        >
          
      <img
        className="icono-social "
        id="i5168l"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qaabv.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="ip0mgf"
          href="https://web.facebook.com/evangelinabarquerotv"
        >
          
      <img
        className=""
        id="ivsl9l"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qbtmw.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="i8q25i"
          href="https://www.instagram.com/evangelinabarquerotv/"
        >
          
      <img
        className="icono-social "
        id="ijorsa"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qcm9q.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <p className="" id="i2qszk">
        <span>Todos los derechos reservados, 2024 ©</span>
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Psobremi);
  