
import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Switch} from 'react-router-dom';
import AOS from 'aos';
import {connect} from 'react-redux';
import {addLoftyUser, deleteLoftytoken} from './actions/auth.actions';

// pages
import sobremi from './pages/sobremi/sobremi'
import paginainicio from './pages/paginainicio/paginainicio'
import muypronto from './pages/muypronto/muypronto'
import contacto from './pages/contacto/contacto'
import homepage from './pages/homepage/homepage'
import aboutme from './pages/aboutme/aboutme'
import contactpage from './pages/contactpage/contactpage'
import articulom4kjaj from './pages/articulom4kjaj/articulom4kjaj'
import articlepage2nc9h from './pages/articlepage2nc9h/articlepage2nc9h'
import error404 from './pages/error404/error404'


// css
import './App.css';
import 'aos/dist/aos.css';

AOS.init();

function initSwiper() {
  // eslint-disable-next-line no-undef
  const swiper = new Swiper('.mySwiper', {
    spaceBetween: 30,
    centeredSlides: true,
    autoplay: false,
    pagination: {
      el: '.swiper-pagination',
      clickable: true,
    },
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
  });
  // console.log('swiper :>> ', swiper);
}

const App = ({loftytoken, addLoftyUserApp, deleteLoftytokenApp}) => {

  // agregando iniciación para el Swipper
  useEffect(() => {
    setTimeout(() => {
      initSwiper();
    }, 2000);
  }, []);

  

  return (
    <Router>
      <Switch>
			<Route exact path="/mihistoria" component={sobremi} />
			<Route exact path="/" component={paginainicio} />
			<Route exact path="/muypronto" component={muypronto} />
			<Route exact path="/contacto" component={contacto} />
			<Route exact path="/home" component={homepage} />
			<Route exact path="/aboutme" component={aboutme} />
			<Route exact path="/contact" component={contactpage} />
			<Route exact path="/articulo/:id" component={articulom4kjaj} />
			<Route exact path="/article/:id" component={articlepage2nc9h} />
			<Route exact path="*" component={error404} />

      </Switch>
    </Router>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
  addLoftyUserApp: (user) => dispatch(addLoftyUser(user)),
  deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});

export default connect(mapStateToProps, mapDispatchToProps)(App);

