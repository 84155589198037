
import React from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';


import './error404.scss';






const Perror404 = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {




  
  
  
  

  
  return (
    <div id="id666717b4914da0d427fd7f8c" >

      <div id="ipax" className="">
      
      <div
        id="im9vk"
        className=""
        data-aos="fade-up"
        data-aos-duration="undefined"
      >
      
      <div id="ijtt" className="">
      
      <div id="iytc" className="">
      
      <img
        className=""
        id="iczg"
        src="https://assetsprojects.s3.amazonaws.com/406raciltpy3ned.png"
        alt="undefined"
      />
      
      </div>
      
      <p className="" id="itjh">
        <span>Página no encontrada</span>
      </p>
      
      <div id="ixm7l" className="">
      
        <a
          target=""
          className=""
          id="iyd02"
          href={`/`}
        >
          
    <div id="i1rcf" className="">
    <span>Volver</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Perror404);
  