
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontacto } from '../../api/contacto.api'
import {custommostraralinicioespanolbienestarmental_669bebee0a9026961e47bd2a, custommostraralinicioespanolcomunicacion_669beb4c0a9026961e47bcdd } from '../../api/articuloslp6yt.api'

import './paginainicio.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Ppaginainicio = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {

	const [loftyres_669bebee0a9026961e47bd2a_component_i82xme, setloftyres_669bebee0a9026961e47bd2a_component_i82xme] = useState([]);
	const [loftyres_669beb4c0a9026961e47bcdd_component_i9ibhd, setloftyres_669beb4c0a9026961e47bcdd_component_i9ibhd] = useState([]);

useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

	useEffect(() => {
		const handleLoftyData = async () => {
			try {
				const responseLoftyApps = await custommostraralinicioespanolbienestarmental_669bebee0a9026961e47bd2a({
				});
				setloftyres_669bebee0a9026961e47bd2a_component_i82xme(responseLoftyApps.data);
			} catch (loftyerror) {
			}
		}
		handleLoftyData();
	}, []);

	useEffect(() => {
		const handleLoftyData = async () => {
			try {
				const responseLoftyApps = await custommostraralinicioespanolcomunicacion_669beb4c0a9026961e47bcdd({
				});
				setloftyres_669beb4c0a9026961e47bcdd_component_i9ibhd(responseLoftyApps.data);
			} catch (loftyerror) {
			}
		}
		handleLoftyData();
	}, []);


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id65eb698bb0184704f909625b" >

      <div id="ixru" className="">
      
      <div id="i2erdn" className="">
      
      <div id="ij34" className="">
      
      <div id="i7mce" className="">
      
      <img
        className=""
        id="i51eb8"
        src="https://assetsprojects.s3.amazonaws.com/406raciltpy3ned.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i6iq52" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Inicio</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/mihistoria"
        type=""
      >
        <span>Mi Historia</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/contacto"
        type=""
      >
        <span>Contacto</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      <a
        target=""
        className="menu__item "
        id=""
        href="/home"
        type=""
      >
        <span>Ingles</span>
      </a>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="icviq" className="">
      
        <a
          target=""
          className="activo-pagina "
          id="ie1gg"
          href={`/`}
        >
          
    <div id="it4d8" className="">
    <span>Inicio</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="iotjc"
          href={`/mihistoria`}
        >
          
    <div id="infbh" className="">
    <span>Acerca de mí</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="iierj"
          href={`/contacto`}
        >
          
    <div id="incnh" className="">
    <span>Contacto</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
      </div>
      
      <div id="iiezql" className="">
      
        <a
          target=""
          className=""
          id="ijdnxl"
          href="#"
        >
          
      <img
        className=""
        id="iibs47"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am4aa.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="iuuqpo"
          href={`/home`}
        >
          
      <img
        className=""
        id="ildd5p"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am355.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="ilpyc" className="">
      
      <div id="igcbq" className="">
      
      <div
        id="inasp7"
        className=""
        data-aos="fade-right"
        data-aos-duration="2500"
      >
      
      <p className="" id="iqe0az">
        <span>Apasionada de la comunicación efectiva, bientestar mental, la justicia y la información véraz y objetiva</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i0idm" className="">
      
      <div
        id="i2tk3u"
        className=""
        data-aos="fade-down"
        data-aos-duration="2500"
      >
      
      <img
        className=""
        id="iy1jt"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvvkjur9.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="if1pe1" className="backgroundprincipal ">
      
      <div id="i4qrb9" className="">
      
      <div
        id="i8e6v9"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="i4tz1p" className="">
      
        <a
          target=""
          className=""
          id="imc3ig"
          href="#comunicacion"
        >
          
      <div id="ih9tp4" className="">
      
      <img
        className=""
        id="iaos5x"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxthtayv.jpeg"
        alt="undefined"
      />
      
      </div>
      
        </a>
        
      </div>
      
      <p className="" id="irez1h">
        <span>Comunicación</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="iwb8oy" className="">
      
      <div
        id="ip5rr6"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="inh5ag" className="">
      
        <a
          target=""
          className=""
          id="iyeipk"
          href="https://www.imacempro.com/"
        >
          
      <div id="irr5fo" className="">
      
      <img
        className=""
        id="i6izj9"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxthwo4j.jpeg"
        alt="undefined"
      />
      
      </div>
      
        </a>
        
      </div>
      
      <p className="" id="i73e02">
        <span>Emprendimiento</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i0zsf8" className="">
      
      <div
        id="i9nbwk"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="ifzwjk" className="">
      
        <a
          target=""
          className=""
          id="i6pn2h"
          href="#i4d9oj"
        >
          
      <div id="i605vw" className="">
      
      <img
        className=""
        id="io49b2"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxthzalz.jpeg"
        alt="undefined"
      />
      
      </div>
      
        </a>
        
      </div>
      
      <p className="" id="iclfwg">
        <span>Bienestar mental</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ihonp" className="">
      
      <div id="iypl0n" className="">
      
      </div>
      
      <p className="" id="ibxuth">
        <span>ACERCA DE MI</span>
      </p>
      
      <div id="iadgz" className="">
      
      <div
        id="ikdqk8"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="" className="swiper mySwiper ">
      
      <div id="" className="swiper-wrapper ">
      
      <div id="in56fb" className="swiper-slide aboutme-slide ">
      
      <img
        className=""
        id="i3kzpm"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtmxwwq.jpeg"
        alt="undefined"
      />
      
      </div>
      
      <div id="iyob0f" className="swiper-slide aboutme-slide ">
      
      <img
        className=""
        id="iq43rj"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtmyiwk.jpeg"
        alt="undefined"
      />
      
      </div>
      
      <div id="icedxq" className="swiper-slide aboutme-slide ">
      
      <img
        className=""
        id="iirgzr"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtmzg0g.jpeg"
        alt="undefined"
      />
      
      </div>
      
      <div id="itbxkt" className="swiper-slide aboutme-slide ">
      
      <img
        className=""
        id="i6yctk"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtn0dh6.jpeg"
        alt="undefined"
      />
      
      </div>
      
      <div id="i9dvp5" className="swiper-slide aboutme-slide ">
      
      <img
        className=""
        id="i0bl04"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtn1xah.jpeg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="" className="swiper-button-next ">
      
      </div>
      
      <div id="" className="swiper-button-prev ">
      
      </div>
      
      <div id="" className="swiper-pagination ">
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ilb92" className="">
      
      <div
        id="idp6gy"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="i457uh" className="">
      
      <div id="i0zovg" className="">
      
      <p className="" id="ijpivk">
        <span>Hola soy</span>
      <br className="" id="" />
      <span>Evangelina Barquero</span>
      </p>
      
      </div>
      
      <p className="" id="i9w69i">
        <span>Comunicadora con 24 años de experiencia.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Gerente general de IMACEM, presentadora de TV, blogger de TuNota.com, moderadora, maestra de ceremonias y embajadora de marca.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>He Incursionado en TV, radio y la jefatura de protocolo y comunicación de un organismo multilateral.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Brindo asesorías en comunicación e imagen, imparto capacitaciones en oratoria, protocolo, servicio al cliente, responsabilidad social, habilidades blandas, motivacionales, etc.</span>
      </p>
      
      <div id="iq3gwi" className="">
      
      <div id="iwdl5i" className="">
      
        <a
          target=""
          className="botonprincipal "
          id="i77x6k"
          href={`/mihistoria`}
        >
          
    <div id="iscir2" className="">
    <span>Leer más</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i6prvu" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i4d9oj" className="">
      
      <div
        id="i8x9qu"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <p className="" id="ibeprf">
        <span>Bienestar Mental</span>
      </p>
      
        <div id="i82xme" className="">
        
          {
            loftyres_669bebee0a9026961e47bd2a_component_i82xme.map((loftyitem_669bebee0a9026961e47bd2a_component_i82xme) => (
              <div key={loftyitem_669bebee0a9026961e47bd2a_component_i82xme._id} className="" id="ic3f9j">
                
      <div id="icntm" className="">
      
      <div id="icregl" className="">
      
        <a
          target=""
          className=""
          id="iaznvj"
          href={`/articulo/${loftyitem_669bebee0a9026961e47bd2a_component_i82xme._id}`}
        >
          
        <div className="" id="i7e0gm">
          
          <div className="" id="ilpgjq">
            {loftyitem_669bebee0a9026961e47bd2a_component_i82xme?.titulozeqtr}
          </div>
          
        </div>
        
        </a>
        
      </div>
      
      <div id="inlwzs" className="">
      
      <div id="i2sqf7" className="">
      
      </div>
      
      <div id="iw6aen" className="">
      
      </div>
      
      </div>
      
        <div className="" id="iz58vl">
          
          <div className="" id="iz3fe4">
            {loftyitem_669bebee0a9026961e47bd2a_component_i82xme?.descripcioncortacbed5k}
          </div>
          
        </div>
        
      </div>
      
              </div>
            ))
          }
          
        </div>
        
      </div>
      
      </div>
      
      <div id="i0kwi" className="">
      
      <div
        id="i0uyoc"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="iy7001" className="">
      
      <p className="" id="ispf5r">
        
      <b className="" id="iyuk7m">
        <span>Contáctame</span>
      </b>
      
      </p>
      
      </div>
      
        <div className="" id="i367ii">
          
      <form
        id="illm7i"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontacto({
						nombrecompleto: e.target.nombrecompleto.value,
						correo: e.target.correo.value,
						mensaje: e.target.mensaje.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ihr8s4" className="">
      
      <br className="" id="" />
      
      <input
        name="nombrecompleto"
        type="text"
        placeholder="Nombre Completo"
        className="campos-contacto "
        id="ifrzbt"
      />
      
      </div>
      
      <div id="i65x8n" className="">
      
      <br className="" id="" />
      
      <input
        name="correo"
        type="text"
        placeholder="Correo"
        className="campos-contacto "
        id="iygjnf"
      />
      
      </div>
      
      <div id="i269cz" className="">
      
      <br className="" id="" />
      
      <input
        name="mensaje"
        type="text"
        placeholder="Mensaje"
        className="campos-contacto "
        id="iyid9o"
      />
      
      </div>
      
      <div id="ivb8of" className="">
      
      <button
        type="submit"
        name=""
        id="im973i"
        className=""
        
      >
      
      <div id="inu4vk" className="">
      <span>Enviar</span>
      <span className="" id="iksjh6">
        
      </span>
      
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      <div id="comunicacion" className="">
      
      <div
        id="ivhvdk"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <p className="" id="i89at6">
        <span>Comunicación</span>
      </p>
      
        <div id="i9ibhd" className="">
        
          {
            loftyres_669beb4c0a9026961e47bcdd_component_i9ibhd.map((loftyitem_669beb4c0a9026961e47bcdd_component_i9ibhd) => (
              <div key={loftyitem_669beb4c0a9026961e47bcdd_component_i9ibhd._id} className="" id="impywl">
                
      <div id="ip83sc" className="">
      
      <div id="i3ond6" className="">
      
        <a
          target=""
          className=""
          id="id7808"
          href={`/article/${loftyitem_669beb4c0a9026961e47bcdd_component_i9ibhd._id}`}
        >
          
        <div className="" id="i9zl6l">
          
          <div className="" id="ipg7xl">
            {loftyitem_669beb4c0a9026961e47bcdd_component_i9ibhd?.titulozeqtr}
          </div>
          
        </div>
        
        </a>
        
      </div>
      
      <div id="ip62cl" className="">
      
      <div id="ifnkmn" className="">
      
      </div>
      
      <div id="ildeai" className="">
      
      </div>
      
      </div>
      
        <div className="" id="i2p59k">
          
          <div className="" id="iofdxh">
            {loftyitem_669beb4c0a9026961e47bcdd_component_i9ibhd?.descripcioncortacbed5k}
          </div>
          
        </div>
        
      </div>
      
              </div>
            ))
          }
          
        </div>
        
      </div>
      
      </div>
      
      <div id="imtnsk" className="backgroundprincipal ">
      
      <div id="ip85kg" className="">
      
      <img
        className=""
        id="iy7pfg"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvvmind7.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="igevyf" className="">
      
      <div id="icoicf" className="">
      
        <a
          target=""
          className=""
          id="iep8l"
          href="mailto:info@evangelinabarquero.com"
        >
          
      <img
        className="icono-social "
        id="i7z62"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8q7t76.png"
        alt="undefined"
      />
      
      <p className="" id="izcqh">
        
      <a
        target=""
        className=""
        id="iu16e6"
        href="mailto:contact@imacempro.com"
        type=""
      >
        <span>contact@imacempro.com</span>
      </a>
      
      </p>
      
        </a>
        
      </div>
      
      <div id="iomgs1" className="">
      
      <div id="iy3iba" className="">
      
        <a
          target=""
          className="item-social "
          id="ivvoa6"
          href="https://twitter.com/EvanBarqueroT"
        >
          
      <img
        className="icono-social "
        id="ivczwg"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qaabv.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="irzqjl"
          href="https://web.facebook.com/evangelinabarquerotv"
        >
          
      <img
        className=""
        id="i2seiz"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qbtmw.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="ihqmli"
          href="https://www.instagram.com/evangelinabarquerotv/"
        >
          
      <img
        className="icono-social "
        id="ib3mif"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qcm9q.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <p className="" id="i2qszk">
        <span>Todos los derechos reservados, 2024 ©</span>
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Ppaginainicio);
  