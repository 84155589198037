
import axios from '../config/axios';

export const url = '/api/contacto'


export const createcontacto = ({
  token,
	nombrecompleto,
	correo,
	mensaje,
	loftyFile,

}) => new Promise((resolve, reject) => {
  if (token && nombrecompleto && correo && mensaje ) {

    axios.post(`${url}//create/basic/65e1de891696d1f3abbc7745`, {
		nombrecompleto,
		correo,
		mensaje,

    }, {
      headers: {
        
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch(error => reject(error));
  } else if (!nombrecompleto) {
		reject({
			status: 'error',
			info: 'nombrecompleto no esta definido',
		});
	}  else if (!correo) {
		reject({
			status: 'error',
			info: 'correo no esta definido',
		});
	}  else if (!mensaje) {
		reject({
			status: 'error',
			info: 'mensaje no esta definido',
		});
	}  else {
    reject({
      status: 'error',
      info: 'Token no definido'
    });
  }
});
      

