
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontacto } from '../../api/contacto.api'
import {custommostraralinicioinglesbienestarmental_669bed4d0a9026961e47bdf4, custommostraraliniciocomunicacion_669becad0a9026961e47bda7 } from '../../api/articuloslp6yt.api'

import './homepage.scss';


// Hamburger
function loftyInitHam() {
  try {
  // eslint-disable-next-line no-restricted-globals
    window.addEventListener('click', (event) => {
      let originPointLeft = '';
      const comp = event.target.className;
      if (comp.includes('menu__btn') || comp.includes('span_middle') || comp.includes('span_before') || comp.includes('span_after')) {
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        spanData.forEach((item) => {
          originPointLeft = item.style.left;
        });
        res.forEach((item) => {
          item.style.left = '0px';
        });
        spanData.forEach((item) => {
          item.style.left = '-300px';
        });
        closeBtn.forEach((item) => {
          item.style.left = '10px';
        });
      } else {
        if (comp === 'menu__box') {
          return;
        }
        if (comp === 'linkshref') {
          return;
        }
        if (comp === 'menu__item') {
          return;
        }
        const res = Array.from(document.getElementsByClassName('menu__box'));
        const spanData = Array.from(document.getElementsByClassName('menu__btn'));
        const closeBtn = Array.from(document.getElementsByClassName('menu__close__btn'));
        res.forEach((item) => {
          item.style.left = '-300px';
        });
        spanData.forEach((item) => {
          item.style.left = originPointLeft;
        });
        closeBtn.forEach((item) => {
          item.style.left = '-100%';
        });
      }
    });
  } catch (error) {
    // console.log(error);
  }
}    
    




const Phomepage = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {

	const [loftyres_669bed4d0a9026961e47bdf4_component_i5y73g, setloftyres_669bed4d0a9026961e47bdf4_component_i5y73g] = useState([]);
	const [loftyres_669becad0a9026961e47bda7_component_is7ns9, setloftyres_669becad0a9026961e47bda7_component_is7ns9] = useState([]);

useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);

	useEffect(() => {
		const handleLoftyData = async () => {
			try {
				const responseLoftyApps = await custommostraralinicioinglesbienestarmental_669bed4d0a9026961e47bdf4({
				});
				setloftyres_669bed4d0a9026961e47bdf4_component_i5y73g(responseLoftyApps.data);
			} catch (loftyerror) {
			}
		}
		handleLoftyData();
	}, []);

	useEffect(() => {
		const handleLoftyData = async () => {
			try {
				const responseLoftyApps = await custommostraraliniciocomunicacion_669becad0a9026961e47bda7({
				});
				setloftyres_669becad0a9026961e47bda7_component_is7ns9(responseLoftyApps.data);
			} catch (loftyerror) {
			}
		}
		handleLoftyData();
	}, []);


  
  setTimeout(() => { loftyInitHam(); }, 2000);

  
  

  
  return (
    <div id="id666713a4914da0d427fd7c11" >

      <div id="ixru" className="">
      
      <div id="i2erdn" className="">
      
      <div id="ij34" className="">
      
      <div id="i7mce" className="">
      
      <img
        className=""
        id="i51eb8"
        src="https://assetsprojects.s3.amazonaws.com/406raciltpy3ned.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="i6iq52" className="">
      
      <div id="" className="contentwrapperanimated ">
      
      <div id="" className="container_animated ">
      
      <label id="" className="menu__btn ">
      
      <span className="span_before " id="">
        
      </span>
      
      <span className="span_middle " id="">
        
      </span>
      
      <span className="span_after " id="">
        
      </span>
      
      </label>
      
      <label id="" className="menu__close__btn ">
      
      <span className="span__close__left " id="">
        
      </span>
      
      <span className="span__close__right " id="">
        
      </span>
      
      </label>
      
      <ul id="" className="menu__box ">
        
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="#"
        type=""
      >
        <span>Home</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/aboutme"
        type=""
      >
        <span>About me</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      <a
        target=""
        className="menu__item "
        id=""
        href="/contact"
        type=""
      >
        <span>Contact</span>
      </a>
      
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      <li className="linkshref " id="">
        
      </li>
      
      <a
        target=""
        className="menu__item "
        id=""
        href="/"
        type=""
      >
        <span>Spanish</span>
      </a>
      
      </ul>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="icviq" className="">
      
        <a
          target=""
          className="activo-pagina "
          id="ie1gg"
          href={`/home`}
        >
          
    <div id="it4d8" className="">
    <span>Home</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="iotjc"
          href={`/aboutme`}
        >
          
    <div id="infbh" className="">
    <span>About me</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="iierj"
          href={`/contact`}
        >
          
    <div id="incnh" className="">
    <span>Contact</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
      </div>
      
      <div id="iiezql" className="">
      
        <a
          target=""
          className=""
          id="iuuqpo"
          href={`/`}
        >
          
      <img
        className=""
        id="ildd5p"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am4aa.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="ijdnxl"
          href="#"
        >
          
      <img
        className=""
        id="iibs47"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am355.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="ilpyc" className="">
      
      <div id="igcbq" className="">
      
      <div
        id="inasp7"
        className=""
        data-aos="fade-right"
        data-aos-duration="2500"
      >
      
      <p className="" id="iqe0az">
        <span>Passionate about effective communication, mental well-being, justice, and truthful and objective information.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i0idm" className="">
      
      <div
        id="i2tk3u"
        className=""
        data-aos="fade-down"
        data-aos-duration="2500"
      >
      
      <img
        className=""
        id="iy1jt"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvvkjur9.png"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i8mq1" className="backgroundprincipal ">
      
      <div id="ildkh6" className="">
      
      <div
        id="ibiblr"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="ibp55k" className="">
      
        <a
          target=""
          className=""
          id="iyluno"
          href="#comunicacion"
        >
          
      <div id="iqx9ie" className="">
      
      <img
        className="img-item "
        id="iizc3u"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxthtayv.jpeg"
        alt="undefined"
      />
      
      </div>
      
        </a>
        
      </div>
      
      <p className="" id="im3nng">
        <span>Comunication</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i81gk1" className="">
      
      <div
        id="iqtacu"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="i559j2" className="">
      
        <a
          target=""
          className=""
          id="ica05k"
          href="https://www.imacempro.com/"
        >
          
      <div id="iv1pfr" className="">
      
      <img
        className="img-item "
        id="iv9nbo"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxthwo4j.jpeg"
        alt="undefined"
      />
      
      </div>
      
        </a>
        
      </div>
      
      <p className="" id="irrzh1">
        <span>Entrepreneurship</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i8b9rf" className="">
      
      <div
        id="ivza5c"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="ifo2x4" className="">
      
        <a
          target=""
          className=""
          id="io2k6h"
          href="#i4d9oj"
        >
          
      <div id="i6cwym" className="">
      
      <img
        className="img-item "
        id="ijiuzf"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxthzalz.jpeg"
        alt="undefined"
      />
      
      </div>
      
        </a>
        
      </div>
      
      <p className="" id="ina24s">
        <span>Mental well-being</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="ihonp" className="">
      
      <div id="iypl0n" className="">
      
      </div>
      
      <p className="" id="ibxuth">
        <span>ABOUT ME</span>
      </p>
      
      <div id="iadgz" className="">
      
      <div
        id="ikdqk8"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <img
        className=""
        id="iep08"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx92v7gq.jpeg"
        alt="undefined"
      />
      
      </div>
      
      <div id="ilb92" className="">
      
      <div
        id="idp6gy"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="i457uh" className="">
      
      <div id="i0zovg" className="">
      
      <p className="" id="ijpivk">
        <span>Hello, I am Evangelina Barquero</span>
      </p>
      
      </div>
      
      <p className="" id="i9w69i">
        <span>Communicator with 24 years of experience.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>General Manager of IMACEM, TV presenter, blogger at TuNota.com, moderator, master of ceremonies, and brand ambassador.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I have ventured into TV, radio, and the head of protocol and communication at a multilateral organization.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I provide consulting in communication and image, and offer training in public speaking, protocol, customer service, social responsibility, soft skills, motivational topics, etc.</span>
      </p>
      
      <div id="iq3gwi" className="">
      
      <div id="iwdl5i" className="">
      
        <a
          target=""
          className="botonprincipal "
          id="i77x6k"
          href={`/aboutme`}
        >
          
    <div id="iscir2" className="">
    <span>Read more</span>
    </div>
    
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i6prvu" className="">
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i4d9oj" className="">
      
      <div
        id="i8x9qu"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <p className="" id="ibeprf">
        <span>Mental well-being</span>
      </p>
      
        <div id="i5y73g" className="">
        
          {
            loftyres_669bed4d0a9026961e47bdf4_component_i5y73g.map((loftyitem_669bed4d0a9026961e47bdf4_component_i5y73g) => (
              <div key={loftyitem_669bed4d0a9026961e47bdf4_component_i5y73g._id} className="" id="i77vp4">
                
      <div id="icntm" className="">
      
      <div id="icregl" className="">
      
        <a
          target=""
          className=""
          id="imcik2"
          href={`/article/${loftyitem_669bed4d0a9026961e47bdf4_component_i5y73g._id}`}
        >
          
        <div className="" id="ioioew">
          
          <div className="" id="ik51ze">
            {loftyitem_669bed4d0a9026961e47bdf4_component_i5y73g?.titulozeqtr}
          </div>
          
        </div>
        
        </a>
        
      </div>
      
      <div id="inlwzs" className="">
      
      <div id="i2sqf7" className="">
      
      </div>
      
      <div id="iw6aen" className="">
      
      </div>
      
      </div>
      
        <div className="" id="ijk3mr">
          
          <div className="" id="inmxi4">
            {loftyitem_669bed4d0a9026961e47bdf4_component_i5y73g?.descripcioncortacbed5k}
          </div>
          
        </div>
        
      </div>
      
              </div>
            ))
          }
          
        </div>
        
      </div>
      
      </div>
      
      <div id="i0kwi" className="">
      
      <div
        id="i0uyoc"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="iy7001" className="">
      
      <p className="" id="ispf5r">
        
      <b className="" id="iyuk7m">
        <span>Contact me</span>
      </b>
      
      </p>
      
      </div>
      
        <div className="" id="i367ii">
          
      <form
        id="illm7i"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontacto({
						nombrecompleto: e.target.nombrecompleto.value,
						correo: e.target.correo.value,
						mensaje: e.target.mensaje.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ihr8s4" className="">
      
      <br className="" id="" />
      
      <input
        name="nombrecompleto"
        type="text"
        placeholder="Full name"
        className="campos-contacto "
        id="ifrzbt"
      />
      
      </div>
      
      <div id="i65x8n" className="">
      
      <br className="" id="" />
      
      <input
        name="correo"
        type="text"
        placeholder="Email"
        className="campos-contacto "
        id="iygjnf"
      />
      
      </div>
      
      <div id="i269cz" className="">
      
      <br className="" id="" />
      
      <input
        name="mensaje"
        type="text"
        placeholder="Message"
        className="campos-contacto "
        id="iyid9o"
      />
      
      </div>
      
      <div id="ivb8of" className="">
      
      <button
        type="submit"
        name=""
        id="im973i"
        className=""
        
      >
      
      <div id="inu4vk" className="">
      <span>Send</span>
      <span className="" id="iksjh6">
        
      </span>
      
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      <div id="comunicacion" className="">
      
      <div
        id="ivhvdk"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <p className="" id="i89at6">
        <span>Comunication</span>
      </p>
      
      </div>
      
        <div id="is7ns9" className="">
        
          {
            loftyres_669becad0a9026961e47bda7_component_is7ns9.map((loftyitem_669becad0a9026961e47bda7_component_is7ns9) => (
              <div key={loftyitem_669becad0a9026961e47bda7_component_is7ns9._id} className="" id="in6ldj">
                
      <div id="ip83sc" className="">
      
      <div id="i3ond6" className="">
      
        <div className="" id="itm6rk">
          
          <div className="" id="in24te">
            {loftyitem_669becad0a9026961e47bda7_component_is7ns9?.titulozeqtr}
          </div>
          
        </div>
        
      </div>
      
      <div id="ip62cl" className="">
      
      <div id="ifnkmn" className="">
      
      </div>
      
      <div id="ildeai" className="">
      
      </div>
      
      </div>
      
        <div className="" id="i0phvf">
          
          <div className="" id="ihjy8k">
            {loftyitem_669becad0a9026961e47bda7_component_is7ns9?.titulozeqtr}
          </div>
          
        </div>
        
      </div>
      
              </div>
            ))
          }
          
        </div>
        
      </div>
      
      <div id="imtnsk" className="backgroundprincipal ">
      
      <div id="ip85kg" className="">
      
      <img
        className=""
        id="iy7pfg"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvvmind7.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="igevyf" className="">
      
      <div id="icoicf" className="">
      
        <a
          target=""
          className=""
          id="iep8l"
          href="mailto:info@evangelinabarquero.com"
        >
          
      <img
        className="icono-social "
        id="i7z62"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8q7t76.png"
        alt="undefined"
      />
      
      <p className="" id="izcqh">
        
      <a
        target=""
        className=""
        id="iu16e6"
        href="mailto:contact@imacempro.com"
        type=""
      >
        <span>contact@imacempro.com</span>
      </a>
      
      </p>
      
        </a>
        
      </div>
      
      <div id="iomgs1" className="">
      
      <div id="iy3iba" className="">
      
        <a
          target=""
          className="item-social "
          id="ivvoa6"
          href="https://twitter.com/EvanBarqueroT"
        >
          
      <img
        className="icono-social "
        id="ivczwg"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qaabv.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="irzqjl"
          href="https://web.facebook.com/evangelinabarquerotv"
        >
          
      <img
        className=""
        id="i2seiz"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qbtmw.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="ihqmli"
          href="https://www.instagram.com/evangelinabarquerotv/"
        >
          
      <img
        className="icono-social "
        id="ib3mif"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qcm9q.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <p className="" id="i2qszk">
        <span>All rights reserved, 2024 ©</span>
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Phomepage);
  