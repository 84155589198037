
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontacto } from '../../api/contacto.api'
import {getOnearticuloslp6yt } from '../../api/articuloslp6yt.api'

import './articlepage2nc9h.scss';






const Particlepage2nc9h = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {

	const [loftyendpoint_669bedf40a9026961e47be7e, setLoftyEndpoint_669bedf40a9026961e47be7e] = useState({});
	
useEffect(() => {
		const loftyHandleData = async () => {
			const loftyres_669bedf40a9026961e47be7e = await getOnearticuloslp6yt({ _id: match.params.id, token: loftytoken || 'asdsa' });
			setLoftyEndpoint_669bedf40a9026961e47be7e(loftyres_669bedf40a9026961e47be7e.data);
		}
		loftyHandleData();
	}, []);


  
  
  
  

  
  return (
    <div id="id669beee9914da0d427ff1f3e" >

      <div id="ixru" className="">
      
      <div id="ij34" className="">
      
      <div id="i7mce" className="">
      
      <img
        className=""
        id="ikq21p"
        src="https://assetsprojects.s3.amazonaws.com/406raciltpy3ned.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="icviq" className="">
      
        <a
          target=""
          className=""
          id="ie1gg"
          href={`/home`}
        >
          
    <div id="it4d8" className="">
    <span>Home</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
        <a
          target=""
          className="activo-pagina "
          id="iotjc"
          href="#"
        >
          
    <div id="infbh" className="">
    <span>About me</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="iierj"
          href={`/contact`}
        >
          
    <div id="incnh" className="">
    <span>Contact</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
      </div>
      
      <div id="is5eph" className="">
      
        <a
          target=""
          className=""
          id="iak5x5"
          href={`/mihistoria`}
        >
          
      <img
        className=""
        id="i3qsiz"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am4aa.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="imj1uz"
          href={`/aboutme`}
        >
          
      <img
        className=""
        id="id3wfp"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am355.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="ihonp" className="">
      
        <div id="i5jf2" className="">
        
        <div className="" id="ivjxy">
          
          <div className="" id="iq9tn">
            {loftyendpoint_669bedf40a9026961e47be7e?.titulozeqtr}
          </div>
          
        </div>
        
        <div className="" id="i6a0ra">
          
          <LoftyEditorJsReadOnly id="undefined" className="" defaultValue={loftyendpoint_669bedf40a9026961e47be7e.contenidokxwy1} />
          
        </div>
        
        </div>
        
      </div>
      
      <div id="i0kwi" className="">
      
      <div
        id="i0uyoc"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="ikxkxh" className="">
      
      <p className="" id="ispf5r">
        
      <b className="" id="iyuk7m">
        <span>Contact me</span>
      </b>
      
      </p>
      
      </div>
      
        <div className="" id="i367ii">
          
      <form
        id="illm7i"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontacto({
						nombrecompleto: e.target.nombrecompleto.value,
						correo: e.target.correo.value,
						mensaje: e.target.mensaje.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ihr8s4" className="">
      
      <br className="" id="" />
      
      <input
        name="nombrecompleto"
        type="text"
        placeholder="Nombre Completo"
        className="campos-contacto "
        id="ifrzbt"
      />
      
      </div>
      
      <div id="i65x8n" className="">
      
      <br className="" id="" />
      
      <input
        name="correo"
        type="text"
        placeholder="Correo"
        className="campos-contacto "
        id="iygjnf"
      />
      
      </div>
      
      <div id="i269cz" className="">
      
      <br className="" id="" />
      
      <input
        name="mensaje"
        type="text"
        placeholder="Mensaje"
        className="campos-contacto "
        id="iyid9o"
      />
      
      </div>
      
      <div id="ivb8of" className="">
      
      <button
        type="submit"
        name=""
        id="im973i"
        className=""
        
      >
      
      <div id="inu4vk" className="">
      <span>Enviar</span>
      <span className="" id="iksjh6">
        
      </span>
      
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      <div id="imtnsk" className="backgroundprincipal ">
      
      <div id="i5ajok" className="">
      
      <img
        className=""
        id="i1fr26"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvvmind7.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="igc5kf" className="">
      
      <div id="iu4568" className="">
      
        <a
          target=""
          className=""
          id="iep8l"
          href="mailto:info@evangelinabarquero.com"
        >
          
      <img
        className="icono-social "
        id="i7z62"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8q7t76.png"
        alt="undefined"
      />
      
      <p className="" id="izcqh">
        
      <a
        target=""
        className=""
        id="iay8fh"
        href="mailto:contact@imacempro.com"
        type=""
      >
        <span>contact@imacempro.com</span>
      </a>
      
      </p>
      
        </a>
        
      </div>
      
      <div id="i13qdh" className="">
      
      <div id="ipxsda" className="">
      
        <a
          target=""
          className="item-social "
          id="ifnwb1"
          href="https://twitter.com/EvanBarqueroT"
        >
          
      <img
        className="icono-social "
        id="i5168l"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qaabv.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="ip0mgf"
          href="https://web.facebook.com/evangelinabarquerotv"
        >
          
      <img
        className=""
        id="ivsl9l"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qbtmw.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="i8q25i"
          href="https://www.instagram.com/evangelinabarquerotv/"
        >
          
      <img
        className="icono-social "
        id="ijorsa"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qcm9q.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <p className="" id="i2qszk">
        <span>All rights reserved, 2024 ©</span>
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Particlepage2nc9h);
  