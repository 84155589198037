
import axios from '../config/axios';

export const url = '/api/articuloslp6yt'


export const custommostraralinicioespanolcomunicacion_669beb4c0a9026961e47bcdd = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/mostrar/inicio/comunicacion/espanol`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const custommostraralinicioespanolbienestarmental_669bebee0a9026961e47bd2a = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/mostrar/inicio/bienestarmental/espanol`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const custommostraraliniciocomunicacion_669becad0a9026961e47bda7 = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/mostrar/inicio/ingles/comunicacion`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const custommostraralinicioinglesbienestarmental_669bed4d0a9026961e47bdf4 = ({
  
}) => new Promise((resolve, reject) => {
  if (true) {
    axios.get(`${url}/custom/mostrar/inicio/ingles/bienestaringles`, {
      params: {
        
      },
      
    }).then((res) => {
      if (res.data.status === 'success') {
        resolve(res.data);
      } else {
        reject(res.data); 
      }
    }).catch((error) => {
      reject(error?.response?.data);
    });
  } else {
    reject({
      status: 'error',
      info: 'Verifique los parametros requeridos',
    });
  }
});


export const getOnearticuloslp6yt = ({
  token,
  _id,
}) => new Promise((resolve, reject) => {
  if (token && _id) {
    axios.get(`${url}//one/basic/669bedf40a9026961e47be7e/${_id}`, {
      headers: {
        Authorization: token,
      }
    }).then((res) => {
      const {data} = res;
      if (data.status === 'success') {
        resolve(data);
      } else {
        reject(data);
      }
    }).catch((error) => reject(error));
  } else if (!_id) {
    reject({
      status: 'error',
      info: 'Identificador no definido'
    });
  } else {
    reject({
      status: 'error',
      info: 'Token no definido',
    });
  }
});
      

