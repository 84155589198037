
import React, {useEffect, useState} from 'react';

import {connect} from 'react-redux';
import LoftyEditorJsReadOnly from '../../components/LoftyEditorJsReadOnly';
import LoftyEditorJs from '../../components/LoftyEditorJs';

import { addLoftyToken, deleteLoftytoken } from '../../actions/auth.actions';

import {createcontacto } from '../../api/contacto.api'

import './aboutme.scss';






const Paboutme = ({
  match,
  history,
  loftytoken,
  loftyuser,
  addLoftyUserApp,
  deleteLoftytokenApp,
  addLoftyTokenApp,
  
}) => {


useEffect(() => {
		const loftyHandleData = async () => {
		}
		loftyHandleData();
	}, []);


  
  
  
  

  
  return (
    <div id="id666713c5914da0d427fd7c20" >

      <div id="ixru" className="">
      
      <div id="ij34" className="">
      
      <div id="i7mce" className="">
      
      <img
        className=""
        id="ikq21p"
        src="https://assetsprojects.s3.amazonaws.com/406raciltpy3ned.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="icviq" className="">
      
        <a
          target=""
          className=""
          id="ie1gg"
          href={`/home`}
        >
          
    <div id="it4d8" className="">
    <span>Home</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
        <a
          target=""
          className="activo-pagina "
          id="iotjc"
          href="#"
        >
          
    <div id="infbh" className="">
    <span>About me</span>
    </div>
    
        </a>
        
        <a
          target=""
          className=""
          id="iierj"
          href={`/contact`}
        >
          
    <div id="incnh" className="">
    <span>Contact</span>
      <br className="" id="" />
      
    </div>
    
        </a>
        
      </div>
      
      <div id="is5eph" className="">
      
        <a
          target=""
          className=""
          id="iak5x5"
          href={`/mihistoria`}
        >
          
      <img
        className=""
        id="i3qsiz"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am4aa.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className=""
          id="imj1uz"
          href={`/aboutme`}
        >
          
      <img
        className=""
        id="id3wfp"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilx8am355.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      <div id="ihonp" className="">
      
      <div id="i4fgxk" className="">
      
      </div>
      
      <div id="iuapqr" className="">
      
      <div
        id="ig5e5f"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <p className="" id="i6qam9">
        <span>About me</span>
      </p>
      
      <div id="" className="swiper mySwiper ">
      
      <div id="" className="swiper-wrapper ">
      
      <div id="i39up1" className="swiper-slide ">
      
      <img
        className=""
        id="igwqg2"
        src="https://assetsprojects.s3.amazonaws.com/406raciltj2jpcx.jpg"
        alt="undefined"
      />
      
      </div>
      
      <div id="i84icg" className="swiper-slide ">
      
      <img
        className=""
        id="ioqfko"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtibr8y.jpeg"
        alt="undefined"
      />
      
      </div>
      
      <div id="ib5xjl" className="swiper-slide ">
      
      <img
        className=""
        id="ikdwc1"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxticls1.jpeg"
        alt="undefined"
      />
      
      </div>
      
      </div>
      
      <div id="" className="swiper-button-next ">
      
      </div>
      
      <div id="" className="swiper-button-prev ">
      
      </div>
      
      <div id="" className="swiper-pagination ">
      
      </div>
      
      </div>
      
      <img
        className="img-sobremi "
        id="im9g2x"
        src="https://assetsprojects.s3.amazonaws.com/406raciltj2jpcx.jpg"
        alt="undefined"
      />
      
      <img
        className="img-sobremi "
        id="i86czz"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxtibr8y.jpeg"
        alt="undefined"
      />
      
      <img
        className="img-sobremi "
        id="i8ji75"
        src="https://assetsprojects.s3.amazonaws.com/eewk5ilxticls1.jpeg"
        alt="undefined"
      />
      
      </div>
      
      <div
        id="ilfx8a"
        className="backgroundprincipal "
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <div id="izmiak" className="">
      
      <p className="" id="inozh">
        <span>Mother of two, in love with life and the extraordinary, I am passionate about mental well-being to achieve continuous personal and professional growth. As Director and General Manager of IMACEN Consultores S.A., I advise you on communication and image matters and offer various training and services in moderation and brand ambassadorship. As a life enthusiast, I share experiences and lessons from the heart that I hope will aid you in your integral growth towards your best version. Here is a bit of what I've learned from life so far:</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned that happiness is living each moment to the fullest… never giving up on dreams and that it’s never too late to start, that craziness is the spice of life. That empathy is vital and brings us closer to others, that you shouldn’t judge anyone; you don’t know their battles and burdens.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned that accepting God's will brings peace but that you must make things happen; what is worthwhile is not easy.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned to tolerate, that rigid beliefs do more harm than good, but principles and values are non-negotiable. To be resilient and to move forward with strength and love.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned to show my children how human and imperfect I am, to feel every emotion from beginning to end. That sometimes the family structure changes, and it’s not the end, it’s part of life. That endings allow the beginning of wonderful stages.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned that no matter the trial, God is there. That suffering is inevitable, and when it comes, in whatever form, we must accept it and face it with open arms, not out of resignation, but out of realism. That there must be a lesson in every suffering, because if not, it would have been in vain.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned to swallow bitterness and smile with sweetness. That it’s as important to laugh as it is to cry.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned to forgive and to be forgiven, to forget and to prioritize. That private life should remain private, not for appearance's sake, but out of respect.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned to be more grateful and with more intensity. To enjoy the moment, to value the instant, and to embrace memories… to give more of my time and knowledge to others.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned that we must always be real and that sacrifices ennoble our hearts. To live more intensely each experience, to enjoy and to love better and more deeply my children, family, and friends. That to dance and sing I only need myself. That there are unique opportunities we shouldn’t miss.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned to be more available to listen and help, to live being present every second. To express passionately what I feel and to remain silent to avoid hurting, to be more human and acknowledge my virtues and flaws.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned to remember that the magic between people and situations exists. That I will never stop learning, fighting, winning, losing, making mistakes, and getting it right, and that on this life path, we must be light… that we all feel, suffer, celebrate, and react differently and that must be respected.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned that solitude is a gift if you come to understand it and that you must be happy first to be happy with others.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>I learned to be my best version and understood that the day I fulfill my mission, that will be my last day, neither before nor after.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>So here I am, with open arms receiving everything life sends that I cannot control; and creating works of art with what I can.</span>
      <br className="" id="" />
      
      <br className="" id="" />
      <span>Happy, fulfilled, passionate, complete, and proud of the scars life leaves me because they remind me of the beauty I have lived.</span>
      <br className="" id="" />
      
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i5iwlc" className="">
      
      <div id="i1a2e2" className="">
      
      <p className="" id="ih4brn">
        <span>Education</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i2alg" className="">
      
      <div
        id="i62gd"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <div
        id="i61c44"
        className=""
        data-aos="fade-down"
        data-aos-duration=""
      >
      
      <div id="igzrx" className="item-info ">
      
      <div id="inn52i" className="">
      
      <div id="ipjf5e" className="">
      
      <div id="idqgxz" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="itraap" className="">
      
      <p className="" id="iijj63">
        <span>2022 - 2024</span>
      </p>
      
      <p className="" id="inl3fr">
        <span>Máster en Comunicación e Identidad Corporativa</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i4dziv" className="">
      
      <div id="i1rzgy" className="">
      
      <div id="i3ix44" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="i3nkjw" className="">
      
      <p className="" id="i9u5nf">
        <span>2006 - 2007</span>
      </p>
      
      <p className="" id="ivbjb3">
        <span>Máster en Dirección y Administración de Empresas, MBA</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i3ccyu" className="">
      
      <div id="iqfi7v" className="">
      
      <div id="i32chu" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="itb8q2" className="">
      
      <p className="" id="ix5eog">
        <span>1998 - 2003</span>
      </p>
      
      <p className="" id="izrqeh">
        <span>Licenciatura en Comunicación y Publicidad, Estudios sobre comunicación y medios</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i8w4dl" className="">
      
      <div id="ius19c" className="">
      
      <p className="" id="iljrou">
        <span>Work</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i9k90x" className="">
      
      <div
        id="irbt2i"
        className=""
        data-aos="fade-down"
        data-aos-duration="1000"
      >
      
      <div
        id="it70au"
        className=""
        data-aos="fade-down"
        data-aos-duration=""
      >
      
      <div id="in2zjr" className="item-info ">
      
      <div id="i2vjm8" className="">
      
      <div id="ilvv9p" className="">
      
      <div id="igesk2" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="iw1aq6" className="">
      
      <p className="" id="iobyrj">
        <span>2024 - Presente</span>
      </p>
      
      <p className="" id="i7dv09">
        <span>Directora y Gerente General de IMACEM Consultores S.A.</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i8hzwa" className="">
      
      <div id="ivw2ch" className="">
      
      <div id="iz782j" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="ilprw6" className="">
      
      <p className="" id="i46gwu">
        <span>2019 - 2020</span>
      </p>
      
      <p className="" id="i6t2gv">
        <span>Radio Talk Show Host de Central Hondureña de Radios - Suprema FM</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i5cjqj" className="">
      
      <div id="ii33y5" className="">
      
      <div id="i4g3yn" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="i2rnww" className="">
      
      <p className="" id="il8k1j">
        <span>2001 - Presente</span>
      </p>
      
      <p className="" id="idoj0p">
        <span>Maestra de Ceremonias - Moderadora, Freelance</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="il84dp" className="">
      
      <div id="i4g3vk" className="">
      
      <div id="iafxd5" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="ix142j" className="">
      
      <p className="" id="i43pv2">
        <span>2009 - 2019</span>
      </p>
      
      <p className="" id="ijqop8">
        <span>Presentadora / Coordinadora Noticiero de Televisión de Televicentro y Emisoras Unidas</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="i0y9sl" className="">
      
      <div id="id6cbq" className="">
      
      <div id="i2octj" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="ivja9p" className="">
      
      <p className="" id="igrplm">
        <span>2004 - 2008</span>
      </p>
      
      <p className="" id="i6ci6f">
        <span>Jefe de Protocolo de Banco Centroamericano de Integración Económica</span>
      </p>
      
      </div>
      
      </div>
      
      <div id="ihfuyv" className="">
      
      <div id="i7u9gn" className="">
      
      <div id="iaissi" className="circulo ">
      
      </div>
      
      </div>
      
      <div id="im454f" className="">
      
      <p className="" id="ic332g">
        <span>2001 - 2004</span>
      </p>
      
      <p className="" id="iwwuze">
        <span>Productora y News Anchor de Televicentro y Emisoras Unidas</span>
      </p>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      </div>
      
      <div id="i0kwi" className="">
      
      <div
        id="i0uyoc"
        className=""
        data-aos="fade-down"
        data-aos-duration="2000"
      >
      
      <div id="ikxkxh" className="">
      
      <p className="" id="ispf5r">
        
      <b className="" id="iyuk7m">
        <span>Contact me</span>
      </b>
      
      </p>
      
      </div>
      
        <div className="" id="i367ii">
          
      <form
        id="illm7i"
        className=""
        onSubmit={async (e) => {
				e.preventDefault();
				try {
					createcontacto({
						nombrecompleto: e.target.nombrecompleto.value,
						correo: e.target.correo.value,
						mensaje: e.target.mensaje.value,
					});
					alert("Se agrego de manera exitosa el registro");
		} catch (error) {
					console.log(error);
				}
			}}
		
      >
        
      <div id="ihr8s4" className="">
      
      <br className="" id="" />
      
      <input
        name="nombrecompleto"
        type="text"
        placeholder="Nombre Completo"
        className="campos-contacto "
        id="ifrzbt"
      />
      
      </div>
      
      <div id="i65x8n" className="">
      
      <br className="" id="" />
      
      <input
        name="correo"
        type="text"
        placeholder="Correo"
        className="campos-contacto "
        id="iygjnf"
      />
      
      </div>
      
      <div id="i269cz" className="">
      
      <br className="" id="" />
      
      <input
        name="mensaje"
        type="text"
        placeholder="Mensaje"
        className="campos-contacto "
        id="iyid9o"
      />
      
      </div>
      
      <div id="ivb8of" className="">
      
      <button
        type="submit"
        name=""
        id="im973i"
        className=""
        
      >
      
      <div id="inu4vk" className="">
      <span>Enviar</span>
      <span className="" id="iksjh6">
        
      </span>
      
      </div>
      
      </button>
      
      </div>
      
      </form>
      
        </div>
        
      </div>
      
      </div>
      
      <div id="imtnsk" className="backgroundprincipal ">
      
      <div id="i5ajok" className="">
      
      <img
        className=""
        id="i1fr26"
        src="https://assetsprojects.s3.amazonaws.com/eewk2ilvvmind7.png"
        alt="undefined"
      />
      
      </div>
      
      <div id="igc5kf" className="">
      
      <div id="iu4568" className="">
      
        <a
          target=""
          className=""
          id="iep8l"
          href="mailto:info@evangelinabarquero.com"
        >
          
      <img
        className="icono-social "
        id="i7z62"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8q7t76.png"
        alt="undefined"
      />
      
      <p className="" id="izcqh">
        
      <a
        target=""
        className=""
        id="iay8fh"
        href="mailto:contact@imacempro.com"
        type=""
      >
        <span>contact@imacempro.com</span>
      </a>
      
      </p>
      
        </a>
        
      </div>
      
      <div id="i13qdh" className="">
      
      <div id="ipxsda" className="">
      
        <a
          target=""
          className="item-social "
          id="ifnwb1"
          href="https://twitter.com/EvanBarqueroT"
        >
          
      <img
        className="icono-social "
        id="i5168l"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qaabv.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="ip0mgf"
          href="https://web.facebook.com/evangelinabarquerotv"
        >
          
      <img
        className=""
        id="ivsl9l"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qbtmw.png"
        alt="undefined"
      />
      
        </a>
        
        <a
          target=""
          className="item-social "
          id="i8q25i"
          href="https://www.instagram.com/evangelinabarquerotv/"
        >
          
      <img
        className="icono-social "
        id="ijorsa"
        src="https://assetsprojects.s3.amazonaws.com/406racilt8qcm9q.png"
        alt="undefined"
      />
      
        </a>
        
      </div>
      
      </div>
      
      </div>
      
      <p className="" id="i2qszk">
        <span>All rights reserved, 2024 ©</span>
      </p>
      
      </div>
      
      </div>
      
</div>
  );
}

const mapStateToProps = (state) => state.auth;

const mapDispatchToProps = (dispatch) => ({
 addLoftyTokenApp: (data) => dispatch(addLoftyToken(data)),
 deleteLoftytokenApp: () => dispatch(deleteLoftytoken()),
});


export default connect(mapStateToProps, mapDispatchToProps)(Paboutme);
  